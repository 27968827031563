import React, { useState } from 'react';
import Modal from 'react-modal';
import { Button, Flex, Text, TextField } from '@aws-amplify/ui-react'; // Import other components from your UI library
import CheckoutForm from './CheckOut';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY);

const customModalStyles = {
    content: {
        position: 'fixed',
        right: '0px', // Set the modal to cover the entire viewport
        top: '0px',
        left: 'auto',
        bottom: '0px',
        width: '25%', // Set the width as desired
        minWidth: '300px',
        maxWidth: '400px', // Adjust as needed
        backgroundColor: 'white',
        border: '1px solid #ccc',
        overflow: 'auto',
        WebkitOverflowScrolling: 'touch',
        outline: 'none',
    },
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Adjust as needed
    }
};


const CheckoutModal = ({ isOpen, onClose, project, donationAmount, onAmountChange, selectedReward }) => {

    const handleRequestClose = () => {
        onClose(); // Call onClose function when the modal is requested to be closed
    };

    return (
        <Modal id="payment-modal"
            // portalClassName="modal"
            style={customModalStyles}
            isOpen={isOpen}
            shouldCloseOnOverlayClick={true}
            onRequestClose={handleRequestClose}>
            <Flex direction="column" gap="20px" padding="20px" >
                <Text fontSize="24px" fontWeight="bold">
                    {`Donate to ${project.name}`}
                </Text>
                <Text>
                    {/* Additional information about the donation or project */}
                    {project.ContributionText}
                </Text>
                {/* Display and edit the donation amount */}
                <Elements stripe={stripePromise}>
                    <CheckoutForm project={project} onClose={onClose} donationAmount={donationAmount} selectedReward={selectedReward} />
                </Elements>
                {/* Close button */}
                <Button onClick={onClose}>Close</Button>
            </Flex>
        </Modal>
    );
};

export default CheckoutModal;
