import { useState, useEffect } from "react";
import "./App.css";
import Footer from "./components/Footer";
import { Routes, Route } from "react-router-dom";
import MainPage from "./pages/MainPage";
import MarketPage from "./pages/MarketPage";
// import { ContextProvider } from "./Context";
import { Context } from "./Context";
import Product from "./pages/Product";
import Navbar from "./components/NavbarHeader_Wrapper";
import Partner from "./pages/Partner";
import Error from "./pages/Error";
import projectDetails from "./Query Components/LookUpProj";
import Modal from 'react-modal';

function App() {
  const [context, setContext] = useState("default context value");
  const [profile, setProfile] = useState("");
  const [profileDis, setProfileDis] = useState("bill.smith@disney.com");
  const [partner, setPartner] = useState(null);
  const [itemId, setItemId] = useState("");
  const [project, setProject] = useState(null);

  useEffect(() => {
    Modal.setAppElement('#root');
  }, []);

  return (
    <Context.Provider

      value={{ itemId, setItemId, profile, setProfile, context, profileDis, partner, setPartner,project,setProject }}
    >
      <main className="w-full bg-">
        <div><Navbar /></div>
        <div className="flex min-h-screen flex-col items-center justify-between pt-12 gap-5 md:gap-1 w-full">
          <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="/:id-marketplace/" element={<MarketPage />} />
            <Route path="/:id-marketplace/:id" element={
              <Product />
            } />
            {/* <Route path="/:prtid-marketplace/:prjid" element={<Product />} /> */}
            <Route path="/marketplace" element={<MarketPage />} />
            <Route path="/marketplace/:id" element={
              <Product />
              } />
            <Route path="/partners" element={<Partner />} />
            <Route path="*" element={<Error />} />
          </Routes>
        </div>
        <Footer />
      </main>
    </Context.Provider>
  );
}

export default App;
