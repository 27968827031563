import React, { useState } from "react";
import Navbar from "../components/Navbar";
import { testDataCopies } from "../MOCKDATA/products";
import { HorizontalCard } from "../components/ui/HorizontalCard";
import Products from "../components/Products";
import { useContext } from "react";
import { Context } from "../Context";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";

export default function MarketPage() {
  const params = useParams();
  //console.log(params);
  const location = useLocation();
  // console.log(location);
  const [disney, setDisney] = useState(params.prtid === "disney");
  return (
    <>
      {/* <Navbar /> */}
      <Products disney={disney} />
    </>
  );
}
