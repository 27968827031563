import React, { useState } from "react";
import { testDataCopies } from "../MOCKDATA/products";
import { HorizontalCard } from "../components/ui/HorizontalCard";
import { Typography } from "@material-tailwind/react";
import { Context } from "../Context";
import { useContext } from "react";
import { FeaturedProjects, Get_Demo_Featured_Projects, Get_Demo_Partner_Projects } from "../Query Components/LookUpProjs";
import ProjectCards from "../ui-components/ProjectCards";
import { useLocation, useParams } from "react-router-dom";

export default function Products() {
  const { profile, profileDis } = useContext(Context);
  const location = useLocation();
  const params = useParams();
  const { PartnerID } = params;

  // // Conditionally fetch projects based on PartnerID presence
  // const projects = PartnerID ? ProjectsByPartner(PartnerID) : FeaturedProjects();
  const projects = PartnerID ? Get_Demo_Partner_Projects() : Get_Demo_Featured_Projects();

  const itemsRendered = projects.map((project) => {
    return <HorizontalCard key={project.id} product={project} />;
  });
    // Keep for eventual UI swap
    // return <ProjectCards project={project} key={project.id} overrides={{
    //   Heading: {},
    //   Body: {},
    //   Message: {},
    //   Button: {},
    //   HeroMessage: {},
    //   Left: {width:"50%"},
    //   image: {objectFit:"cover"},
    //   Right: {width:"50%"},
    //   ProjectCards: {width:"100%",height:"100%"},
    // }} />;
    // console.log(project)
    
  if (profileDis.includes("disney")) {
    //console.log("it does");
  }
  return (
    <>
      <div
        className={`pt-20 w-full flex flex-col items-center gap-10 bg-blue-100`}
      >
        <div className=" w-3/4">
          <Typography variant="h1">Our Products</Typography>
          <Typography variant="lead">
            Welcome to our Products Showcase! Discover a world of innovation and
            quality. Explore a curated collection of products that are designed
            to elevate your life, from the everyday essentials to the
            extraordinary. Whether you&apos;re seeking style, functionality, or
            a little bit of both, we&apos;ve got something special just for you.
            Dive in and find the perfect solution to meet your needs. It&apos;s
            time to shop, discover, and elevate your lifestyle with our
            remarkable range of products
          </Typography>
        </div>
        <div className="mx-auto w-5/6">
          <div className="myGrid">{itemsRendered}</div>
        </div>
      </div>
    </>
  );
}
