import React, { useState } from "react";
import { Typography } from "@material-tailwind/react";
import { HorizontalCard } from "../components/ui/HorizontalCard";

export default function Partner() {
  const [partner, setPartner] = useState({
    about: "partner 1",
    title: "Fresh Air",
    img: "src/images/img4.jpg",
    coin: "etherium",
    cost: "100",
    actions: ["plant trees", "sustainability", "educate"],
    link: "/marketplace/shop/2",
    id: 2,
    payLink: "https://buy.stripe.com/test_bIY1750ohfTM7IYeUY",
  });
  return (
    <>
      <Typography variant="h5" className="max-w-lg">
        Welcome to our website! We are a dedicated team of individuals who are
        deeply concerned about the health of our environment and committed to
        making a positive impact
      </Typography>
      <ul>
        <HorizontalCard product={partner} />
      </ul>
    </>
  );
}
