import {
  Card,
  CardHeader,
  CardBody,
  Typography,
  Button,
} from "@material-tailwind/react";
import { Link } from "react-router-dom";

export function HorizontalCard({ product }) {
  if (!product) {
    // Handle the case where product is undefined, for example, return null or display an error message.
    return null;
  };
  return (
    <Link to={`/marketplace/${product.id}`} className="h-full overflow-hidden">
      <Card className="w-full max-w-[48rem] flex-row h-full overflow-hidden">
        <CardHeader
          shadow={false}
          floated={false}
          className="m-0 w-2/5 shrink-0 rounded-r-none overflow-hidden"
        >
          <img
            src={product?.urlThumbNail}
            alt="card-image"
            className="h-full w-full object-cover"
          />
        </CardHeader>
        <CardBody>
          <Typography variant="h6" color="gray" className="mb-4 uppercase">
            {product.name}
          </Typography>
          <Typography color="gray" className="mb-8 font-normal">
            {product.shortDescription}
          </Typography>
          <Button variant="text" className="flex items-center mx-auto gap-2">
            Donate
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
              className="h-4 w-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
              />
            </svg>
          </Button>
        </CardBody>
      </Card>
    </Link>
  );
}
