import React from "react";
import Survey from "./Survey";
import { Button, Typography } from "@material-tailwind/react";
import mountain from "../assets/mountain.jpg";
import { useContext } from "react";
import { Context } from "../Context";
import MainBanner from "../ui-components/MainBanner.jsx"

export default function Header() {
  const { profile } = useContext(Context);
  const bgGradient = {
    backgroundPosition: "tile",
    backgroundImage: `url('${mountain}')`,
    backgroundSize: "contain",
    backgroundRepeatNoRepeat: "no-repeat"
  };
  const name = profile ? (
    <span className="capitalize">{profile.given_name}</span>
  ) : (
    ""
  );

  return (
    <>
      <MainBanner overrides={{
        MainBanner: { width: "100%" ,height:"400px"}, 
        Banner: { width: "80%" },
        "Background logo 1":{objectPosition:"0% 65%",objectFit:"cover"}
           

        // ,
        // "MainBanner": bgGradient

      }} />
      
    </>
  );
}
