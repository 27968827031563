/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import Logo from "./Logo";
import { Flex, Text, View } from "@aws-amplify/ui-react";
export default function LogoWithText(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: {
        Logo: {},
        Green: {},
        Space: {},
        RR: {},
        "Group 2": {},
        logo: {},
        LogoWithText: {},
      },
      variantValues: { color: "neutral" },
    },
    {
      overrides: {
        Logo: {},
        Green: {},
        Space: {},
        RR: { color: "rgba(67,168,84,1)" },
        "Group 2": {},
        logo: { top: "calc(50% - 14.5px - -1px)" },
        LogoWithText: {},
      },
      variantValues: { color: "brand" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <View
      width="132px"
      height="27px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
      padding="0px 0px 0px 0px"
      {...getOverrideProps(overrides, "LogoWithText")}
      {...rest}
    >
      <Flex
        gap="0"
        direction="row"
        width="127px"
        height="29px"
        justifyContent="center"
        alignItems="center"
        position="absolute"
        top="calc(50% - 14.5px - 0px)"
        left="0px"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "logo")}
      >
        <Logo
          display="flex"
          gap="10px"
          direction="column"
          width="30px"
          height="30px"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          color="brand"
          {...getOverrideProps(overrides, "Logo")}
        ></Logo>
        <Flex
          padding="0px 0px 0px 0px"
          width="92px"
          height="13.09px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          {...getOverrideProps(overrides, "Group 2")}
        >
          <Text
            fontFamily="Inter"
            fontSize="12px"
            fontWeight="600"
            color="rgba(0,0,0,1)"
            lineHeight="14.522727012634277px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="36px"
            height="13px"
            gap="unset"
            alignItems="unset"
            position="absolute"
            top="calc(50% - 6.5px - 0.05px)"
            left="0px"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Green"
            {...getOverrideProps(overrides, "Green")}
          ></Text>
          <Text
            fontFamily="Inter"
            fontSize="12px"
            fontWeight="600"
            color="rgba(0,0,0,1)"
            lineHeight="14.522727012634277px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="38px"
            height="13px"
            gap="unset"
            alignItems="unset"
            position="absolute"
            top="calc(50% - 6.5px - -0.05px)"
            left="54px"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Space"
            {...getOverrideProps(overrides, "Space")}
          ></Text>
          <Text
            fontFamily="Inter"
            fontSize="12px"
            fontWeight="700"
            color="rgba(159,159,159,1)"
            lineHeight="14.522727012634277px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="20px"
            height="12px"
            gap="unset"
            alignItems="unset"
            position="absolute"
            top="calc(50% - 6px - 0.55px)"
            left="35px"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="RR"
            {...getOverrideProps(overrides, "RR")}
          ></Text>
        </Flex>
      </Flex>
    </View>
  );
}
