import { Carousel, Typography, Button } from "@material-tailwind/react";
import { nanoid } from "nanoid";

export function CarouselWithContent({ slides }) {
  const slidesRendered = slides.map((slide) => {
    return (
      <div className="relative h-full w-full" key={nanoid}>
        <img
          src={slide.image}
          alt="image 2"
          className="h-full w-full object-cover"
        />
        <div className="absolute inset-0 grid h-full w-full items-center bg-black/75 ">
          <div className="w-3/4 md:w-2/4 mx-auto">
            <Typography
              variant="h1"
              color="white"
              className="mb-4 text-3xl md:text-4xl lg:text-5xl"
            >
              {slide.title}
            </Typography>
            <Typography
              variant="lead"
              color="white"
              className="mb-12 opacity-80"
            >
              {slide.text}
            </Typography>
          </div>
        </div>
      </div>
    );
  });
  return <Carousel className="h-full">{slidesRendered}</Carousel>;
}
