import React, { useState, useEffect } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { Button, Flex, Text, TextField } from '@aws-amplify/ui-react'; // Import other components from your UI library

const submitButtonStyle = {
  backgroundColor: '#4CAF50', /* Green */
  border: 'none',
  color: 'white',
  padding: '5px 10px',
  textAlign: 'center',
  textDecoration: 'none',
  display: 'inline-block',
  fontSize: '14px',
  borderRadius: '4px',
  cursor: 'pointer',
};


const CheckoutForm = ({ stripe, project, donationAmount, onAmountChange, selectedReward }) => {

  const [Reward, setReward] = useState(selectedReward); // State variable to hold the selected reward
  const stripeInstance = stripe || useStripe();
  const [editableAmount, setEditableAmount] = useState(donationAmount);
  const elements = useElements();

  const [paymentConfirmed, setPaymentConfirmed] = useState(false);

  const handleAmountChange = (event) => {
    setEditableAmount(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Simulate successful payment by setting paymentConfirmed to true
    setPaymentConfirmed(true);
  };

  // Render the confirmation message if payment is confirmed
  if (paymentConfirmed) {
    return (
      <p>
        Payment Confirmed! <br />
        <br /> Thank you for your donation of ${editableAmount}.
        <br />
        <br /> Once fully processed, your reward will reflect on your account.
      </p>
    );
  }

  // Render the payment form if payment is not yet confirmed
  return (
    <form onSubmit={handleSubmit}>
  <Flex direction="column" gap="10px">
    <Flex direction="row" gap="10px" alignItems="center">
      <Text>Selected Reward:</Text>
      <Text>{selectedReward}</Text>
    </Flex>
    <Flex direction="row" gap="10px" alignItems="center">
      <Text>Donation Amount:</Text>
      <TextField
        id="editableAmount"
        type="number"
        value={editableAmount}
        onChange={handleAmountChange}
      />
    </Flex>
    <div style={{ marginTop: '10px' }}> {/* Apply margin to create space */}
      <CardElement />
    </div>
    <button type="submit" disabled={!stripeInstance} style={submitButtonStyle}>
      Submit
    </button>
  </Flex>
</form>

  );
};

export default CheckoutForm;
