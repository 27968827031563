import React from "react";
import GreenUnderline from "./ui/GreenUnderline";
export default function Section() {
  return (
    <>
      <main className="">
        <div className="flex flex-col gap-10 md:gap-0">
          <section className="sec-reg">
            <div className="sec-div">
              <h2 className="lg-txt-m relative">
                The Problem
                <GreenUnderline />
              </h2>
              <p className="ptop text-low-opacity">
                Our governments evolved in a world where resources seemed
                infinite. They cannot manage widespread, long-term existential
                problems like climate change. Nor are they effective at solving
                localized environmental problems.
              </p>
            </div>
            <div
              className={`bg-[url('./assets/pileofwood.jpg')] bg-cover bg-center bg-no-repeat min-h-[40vh] text-slate-100 md:w-1/2`}
            ></div>
          </section>
          {/* <===================== seperator ====================> */}
          <div>
            <section className="sec-rev">
              <div className="sec-div">
                <h2 className="lg-txt-m relative">
                  Our Mission
                  <GreenUnderline />
                </h2>
                <p className="ptop text-low-opacity">
                  We want to make it possible for every person and organization
                  on the planet to work together in an evidence-based way to
                  support environmental and sustainability goals.
                </p>
              </div>
              <div
                className={`bg-[url('./assets/heartpalm.jpg')] bg-cover bg-center bg-no-repeat min-h-[40vh] text-slate-100 md:w-1/2`}
              ></div>
            </section>
          </div>
          {/* <===================== seperator ====================> */}
          <div>
            <section className="sec-reg">
              <div className="sec-div">
                <h2 className="lg-txt-m relative">
                  Our Stakeholders
                  <GreenUnderline />
                </h2>
                <ul className="ptop text-low-opacity">
                  <li>People who care about Planet Earth</li>
                  <li>
                    Non-profits, Foundations and NGOs who share our Mission
                  </li>
                  <li>Research Entities who can propose and vette projects</li>
                  <li>Companies that care about ESG</li>
                </ul>
              </div>
              <div
                className={`bg-[url('./assets/team.jpg')] bg-cover bg-center bg-no-repeat min-h-[40vh] text-slate-100 md:w-1/2`}
              ></div>
            </section>
          </div>
          {/* <===================== seperator ====================> */}
        </div>
      </main>
    </>
  );
}
