import React from "react";
import { SimpleRegistrationForm } from "./ui/SimpleRegistrationForm";
// import specialBG from "/bg1.2.jpg";

export default function ContactForm() {
  const bgGradient = {
    background: "rgb(255,255,255)",
    backgroundImage: `radial-gradient(circle, rgba(255,255,255,0) 0%, rgba(2,6,23,1) 100%), url('../../public/bg1.2.jpg')`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeatNoRepeat: "no-repeat",
  };
  const bgGradientTop = {
    background: "rgb(255,255,255)",
    backgroundImage:
      "linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(2,6,23,1) 100%)",
  };
  const bgGradientBottom = {
    background: "rgb(255,255,255)",
    backgroundImage:
      "linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(2,6,23,1) 100%)",
  };

  return (
    <div className="w-full overflow-hidden text-slate-50">
      <div
        style={bgGradient}
        className="bg-cover bg-no-repeat bg-center py-40 md:text-center md:mx-auto w-full relative"
      >
        <div className="w-5/6 mx-auto flex flex-col gap-10 md:items-center">
          <h3 className="lg-txt">Contact Us</h3>
          <div className="flex flex-col gap-10 w-2/4">
            <div className="flex flex-col gap-5">
              <h4>Let us Know you Care</h4>
              <p>
                We are actively seeking individuals and organizations with deep
                knowledge about sustainability to participate at the Board
                level.
              </p>
            </div>
            <div className="flex flex-col gap-5">
              <h4>Tell us who you are and how you&apos;d like to help.</h4>
              <p>
                We are also seeking Affiliate Board members to serve on
                committees, make evidence-based proposals in support of
                sustainability, perform as Actors, act as Verifiers, and
                generally Get Stuff Done.
              </p>
            </div>
          </div>
          <p className="w-96"></p>
          <SimpleRegistrationForm />
        </div>
      </div>
    </div>
  );
}
