import React from "react";
import { FaTwitter, FaYoutube, FaFacebook, FaInstagram } from "react-icons/fa";
import { HashLink } from "react-router-hash-link";

export default function Footer() {
  return (
    <footer className="bg-stone-950 text-center text-lg">
      <div className="cs-width py-20 md:items-center">
        <div className="flex flex-col flex-wrap justify-center items-center gap-5">
          <div>
            <h2 className="lg-txt w-full ">
              Green<span className="text-green-400">RR</span> Space
            </h2>
            <HashLink to="#top" smooth>
              back to top ^
            </HashLink>
          </div>
          <div className="flex gap-5">
            <h3 className="font-bold mb-2">About </h3>
            <h3 className="font-bold mb-2">Account</h3>
            <h3 className="font-bold mb-2">Crypto</h3>
          </div>
          <div className="">
            <ul className="list-none flex flex-wrap justify-center items-center gap-5 text-lg">
              <li>
                <a href="#">
                  <FaFacebook />
                </a>
              </li>
              <li>
                <a href="#">
                  <FaTwitter />
                </a>
              </li>
              <li>
                <a href="#">
                  <FaInstagram />
                </a>
              </li>
              <li>
                <a href="#">
                  <FaYoutube />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="text-center mt-8">
          <p className="text-gray-500">
            &copy; 2023 GreenRR Space. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}
