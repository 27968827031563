// React
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Link } from "react-router-dom";

// Amplify Studio
import { ThemeProvider } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import studioTheme from "./ui-components/studioTheme";
import { Amplify } from "aws-amplify";
import awsconfig from "./aws-exports";
Amplify.configure(awsconfig);

// Components
import App from "./App.jsx"; // Import your main React component
import "./index.css";

// Retrieve Stripe publishable key from environment variables
const stripePublishableKey = process.env.REACT_APP_PUBLISHABLE_KEY;

// Pass the Stripe publishable key to your main App component
ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={studioTheme}>
        <App stripePublishableKey={stripePublishableKey} />
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>
);
