import React from "react";
import Section from "./Section";

export default function About() {
  return (
    <>
      <div className="flex flex-col gap-20">
        <div className="sec-div md:items-center" id="about">
          <h2 className="lg-txt-x md:text-center lg:text-center">About Us</h2>
        </div>
        <Section />
      </div>
    </>
  );
}
