export const items = [
  {
    about:
      "Antarctica, often considered one of the least populated lands on our planet, stands as an awe-inspiring testament to the raw beauty of untouched wilderness. This remote continent, covered in ice and surrounded by frigid waters, has remained largely free from the influence of human habitation for countless years. The isolation and extreme conditions that define Antarctica have acted as a natural shield, safeguarding its pristine landscapes and unique ecosystems. The benefits of preserving Antarctica&apos;s untouched beauty are manifold. Firstly, it serves as a living laboratory for scientists, offering a rare opportunity to study ecosystems and climate systems unaffected by human interference. This research is invaluable for understanding the global effects of climate change and the importance of preserving such fragile environments. Secondly, the untouched beauty of Antarctica has immense aesthetic and cultural value. Its vast, icy expanses, towering glaciers, and majestic wildlife, including penguins, seals, and whales, provide a profound sense of wonder and connection to the natural world. These untouched vistas inspire artists, writers, and explorers, reminding us of the Earth&apos;s inherent grandeur.",
    title: "The Antartic Project",
    partner: "n/a",
    img: "/img3.jpg",
    coin: "bitcoin",
    cost: "130",
    actions: ["plant trees", "sustainability", "educate"],
    link: "/marketplace/shop/1",
    id: 1,
    payLink: "https://buy.stripe.com/test_5kAbLJ4Ex5f80gw28b",
  },
  {
    about:
      "Antarctica, often considered one of the least populated lands on our planet, stands as an awe-inspiring testament to the raw beauty of untouched wilderness. This remote continent, covered in ice and surrounded by frigid waters, has remained largely free from the influence of human habitation for countless years. The isolation and extreme conditions that define Antarctica have acted as a natural shield, safeguarding its pristine landscapes and unique ecosystems. The benefits of preserving Antarctica&apos;s untouched beauty are manifold. Firstly, it serves as a living laboratory for scientists, offering a rare opportunity to study ecosystems and climate systems unaffected by human interference. This research is invaluable for understanding the global effects of climate change and the importance of preserving such fragile environments. Secondly, the untouched beauty of Antarctica has immense aesthetic and cultural value. Its vast, icy expanses, towering glaciers, and majestic wildlife, including penguins, seals, and whales, provide a profound sense of wonder and connection to the natural world. These untouched vistas inspire artists, writers, and explorers, reminding us of the Earth&apos;s inherent grandeur.",
    title: "Fresh Air",
    partner: "n/a",
    img: "/img4.jpg",
    coin: "etherium",
    cost: "100",
    actions: ["plant trees", "sustainability", "educate"],
    link: "/marketplace/shop/2",
    id: 2,
    payLink: "https://buy.stripe.com/test_bIY1750ohfTM7IYeUY",
  },
  {
    about:
      "Antarctica, often considered one of the least populated lands on our planet, stands as an awe-inspiring testament to the raw beauty of untouched wilderness. This remote continent, covered in ice and surrounded by frigid waters, has remained largely free from the influence of human habitation for countless years. The isolation and extreme conditions that define Antarctica have acted as a natural shield, safeguarding its pristine landscapes and unique ecosystems. The benefits of preserving Antarctica&apos;s untouched beauty are manifold. Firstly, it serves as a living laboratory for scientists, offering a rare opportunity to study ecosystems and climate systems unaffected by human interference. This research is invaluable for understanding the global effects of climate change and the importance of preserving such fragile environments. Secondly, the untouched beauty of Antarctica has immense aesthetic and cultural value. Its vast, icy expanses, towering glaciers, and majestic wildlife, including penguins, seals, and whales, provide a profound sense of wonder and connection to the natural world. These untouched vistas inspire artists, writers, and explorers, reminding us of the Earth&apos;s inherent grandeur.",
    title: "Organic Roots",
    partner: "n/a",
    img: "/img5.jpg",
    coin: "doge",
    cost: ".01",
    actions: ["plant trees", "sustainability", "educate"],
    link: "/marketplace/shop/3",
    id: 3,
    payLink: "https://buy.stripe.com/test_5kA175b2V9vo8N2fYZ",
  },
  {
    about:
      "Antarctica, often considered one of the least populated lands on our planet, stands as an awe-inspiring testament to the raw beauty of untouched wilderness. This remote continent, covered in ice and surrounded by frigid waters, has remained largely free from the influence of human habitation for countless years. The isolation and extreme conditions that define Antarctica have acted as a natural shield, safeguarding its pristine landscapes and unique ecosystems. The benefits of preserving Antarctica&apos;s untouched beauty are manifold. Firstly, it serves as a living laboratory for scientists, offering a rare opportunity to study ecosystems and climate systems unaffected by human interference. This research is invaluable for understanding the global effects of climate change and the importance of preserving such fragile environments. Secondly, the untouched beauty of Antarctica has immense aesthetic and cultural value. Its vast, icy expanses, towering glaciers, and majestic wildlife, including penguins, seals, and whales, provide a profound sense of wonder and connection to the natural world. These untouched vistas inspire artists, writers, and explorers, reminding us of the Earth&apos;s inherent grandeur.",
    title: "Nature takes back",
    partner: "n/a",
    img: "/img2.jpg",
    coin: "cardano",
    cost: "20",
    actions: ["plant trees", "sustainability", "educate"],
    link: "/marketplace/shop/4",
    id: 4,
    payLink: "https://buy.stripe.com/test_dR69DBb2V7ng4wM6oq",
  },
  {
    about:
      "Antarctica, often considered one of the least populated lands on our planet, stands as an awe-inspiring testament to the raw beauty of untouched wilderness. This remote continent, covered in ice and surrounded by frigid waters, has remained largely free from the influence of human habitation for countless years. The isolation and extreme conditions that define Antarctica have acted as a natural shield, safeguarding its pristine landscapes and unique ecosystems. The benefits of preserving Antarctica&apos;s untouched beauty are manifold. Firstly, it serves as a living laboratory for scientists, offering a rare opportunity to study ecosystems and climate systems unaffected by human interference. This research is invaluable for understanding the global effects of climate change and the importance of preserving such fragile environments. Secondly, the untouched beauty of Antarctica has immense aesthetic and cultural value. Its vast, icy expanses, towering glaciers, and majestic wildlife, including penguins, seals, and whales, provide a profound sense of wonder and connection to the natural world. These untouched vistas inspire artists, writers, and explorers, reminding us of the Earth&apos;s inherent grandeur.",
    title: "Sustainable Lands",
    partner: "n/a",
    img: "/img8.jpg",
    coin: "shib",
    cost: "10",
    actions: ["plant trees", "sustainability", "educate"],
    link: "/marketplace/shop/5",
    id: 5,
    payLink: "https://buy.stripe.com/test_3csaHF4Ex0YS1kAfZ3",
  },
  {
    about:
      "The Walt Disney Company, commonly known as Disney, is one of the world's most iconic and influential entertainment conglomerates. Established in 1923 by Walt Disney and Roy O. Disney, this company has not only created timeless characters and stories but has also built a global brand that spans theme parks, movies, television, merchandise, and more. Disney's influence on popular culture and the entertainment industry is immeasurable, and its ability to captivate and inspire audiences of all ages remains unparalleled. Disney's story began in the 1920s when Walt Disney, a visionary animator, and his brother Roy co-founded the Disney Brothers Studio. The studio later became the Walt Disney Studio. Their early successes included the creation of Mickey Mouse in 1928, marking the birth of an enduring cultural icon. The release of 'Snow White and the Seven Dwarfs' in 1937 was a groundbreaking achievement, as it was the world's first full-length animated feature film. This was a pivotal moment for the company, and it solidified Disney's reputation as a pioneer in animation. Disney's dream extended beyond just animated films. In 1955, Disneyland opened its doors in Anaheim, California, becoming the world's first-ever theme park. This marked the beginning of a new era in family entertainment. The park's immense success led to the creation of additional parks worldwide, including Walt Disney World in Florida, Tokyo Disneyland, Disneyland Paris, Hong Kong Disneyland, and Shanghai Disneyland. These theme parks have become destinations that transport visitors to a world of magic, where imagination knows no bounds.",
    title: "Walt Disney",
    partner: "disney",
    img: "/disney.jpg",
    coin: "shib",
    cost: "10",
    actions: ["plant trees", "sustainability", "educate"],
    link: "/marketplace/shop/6",
    id: 6,
    payLink: "https://buy.stripe.com/test_3csaHF4Ex0YS1kAfZ3",
  },
];

export const testDataCopies = [
  ...items,
  ...items,
  ...items,
  ...items,
  ...items,
];

const disneyData = items.find((item) => item.partner === "disney");
export const disneyDataArray = [
  disneyData,
  disneyData,
  disneyData,
  disneyData,
  disneyData,
];

export const partnerNames = ["disney", "nickolodeon", "discovery"];
