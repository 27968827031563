/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCommunity = /* GraphQL */ `
  query GetCommunity($id: ID!) {
    getCommunity(id: $id) {
      id
      name
      Rewards {
        items {
          id
          communityId
          rewardsId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      Projects {
        items {
          id
          communityId
          projectId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCommunities = /* GraphQL */ `
  query ListCommunities(
    $filter: ModelCommunityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommunities(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        Rewards {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRewards = /* GraphQL */ `
  query GetRewards($id: ID!) {
    getRewards(id: $id) {
      id
      Label
      communitys {
        items {
          id
          communityId
          rewardsId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      projects {
        items {
          id
          rewardsId
          projectId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      partners {
        items {
          id
          rewardsId
          partnerId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listRewards = /* GraphQL */ `
  query ListRewards(
    $filter: ModelRewardsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRewards(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Label
        communitys {
          nextToken
          __typename
        }
        projects {
          nextToken
          __typename
        }
        partners {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPartner = /* GraphQL */ `
  query GetPartner($id: ID!) {
    getPartner(id: $id) {
      id
      name
      description
      active
      featured
      img_url
      representative
      Projects {
        items {
          id
          partnerId
          projectId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      Rewards {
        items {
          id
          rewardsId
          partnerId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPartners = /* GraphQL */ `
  query ListPartners(
    $filter: ModelPartnerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPartners(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        active
        featured
        img_url
        representative
        Projects {
          nextToken
          __typename
        }
        Rewards {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProject = /* GraphQL */ `
  query GetProject($id: ID!) {
    getProject(id: $id) {
      id
      name
      featured
      active
      representative
      shortDescription
      longDescription
      ContributionText
      urlBanner
      urlMainImage
      urlContributionImage
      urlThumbNail
      callToAction
      communitys {
        items {
          id
          communityId
          projectId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      Rewards {
        items {
          id
          rewardsId
          projectId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      partners {
        items {
          id
          partnerId
          projectId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listProjects = /* GraphQL */ `
  query ListProjects(
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        featured
        active
        representative
        shortDescription
        longDescription
        ContributionText
        urlBanner
        urlMainImage
        urlContributionImage
        urlThumbNail
        callToAction
        communitys {
          nextToken
          __typename
        }
        Rewards {
          nextToken
          __typename
        }
        partners {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCommunityRewards = /* GraphQL */ `
  query GetCommunityRewards($id: ID!) {
    getCommunityRewards(id: $id) {
      id
      communityId
      rewardsId
      community {
        id
        name
        Rewards {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      rewards {
        id
        Label
        communitys {
          nextToken
          __typename
        }
        projects {
          nextToken
          __typename
        }
        partners {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCommunityRewards = /* GraphQL */ `
  query ListCommunityRewards(
    $filter: ModelCommunityRewardsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommunityRewards(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        communityId
        rewardsId
        community {
          id
          name
          createdAt
          updatedAt
          __typename
        }
        rewards {
          id
          Label
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const communityRewardsByCommunityId = /* GraphQL */ `
  query CommunityRewardsByCommunityId(
    $communityId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCommunityRewardsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    communityRewardsByCommunityId(
      communityId: $communityId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        communityId
        rewardsId
        community {
          id
          name
          createdAt
          updatedAt
          __typename
        }
        rewards {
          id
          Label
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const communityRewardsByRewardsId = /* GraphQL */ `
  query CommunityRewardsByRewardsId(
    $rewardsId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCommunityRewardsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    communityRewardsByRewardsId(
      rewardsId: $rewardsId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        communityId
        rewardsId
        community {
          id
          name
          createdAt
          updatedAt
          __typename
        }
        rewards {
          id
          Label
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCommunityProject = /* GraphQL */ `
  query GetCommunityProject($id: ID!) {
    getCommunityProject(id: $id) {
      id
      communityId
      projectId
      community {
        id
        name
        Rewards {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      project {
        id
        name
        featured
        active
        representative
        shortDescription
        longDescription
        ContributionText
        urlBanner
        urlMainImage
        urlContributionImage
        urlThumbNail
        callToAction
        communitys {
          nextToken
          __typename
        }
        Rewards {
          nextToken
          __typename
        }
        partners {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCommunityProjects = /* GraphQL */ `
  query ListCommunityProjects(
    $filter: ModelCommunityProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommunityProjects(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        communityId
        projectId
        community {
          id
          name
          createdAt
          updatedAt
          __typename
        }
        project {
          id
          name
          featured
          active
          representative
          shortDescription
          longDescription
          ContributionText
          urlBanner
          urlMainImage
          urlContributionImage
          urlThumbNail
          callToAction
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const communityProjectsByCommunityId = /* GraphQL */ `
  query CommunityProjectsByCommunityId(
    $communityId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCommunityProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    communityProjectsByCommunityId(
      communityId: $communityId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        communityId
        projectId
        community {
          id
          name
          createdAt
          updatedAt
          __typename
        }
        project {
          id
          name
          featured
          active
          representative
          shortDescription
          longDescription
          ContributionText
          urlBanner
          urlMainImage
          urlContributionImage
          urlThumbNail
          callToAction
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const communityProjectsByProjectId = /* GraphQL */ `
  query CommunityProjectsByProjectId(
    $projectId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCommunityProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    communityProjectsByProjectId(
      projectId: $projectId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        communityId
        projectId
        community {
          id
          name
          createdAt
          updatedAt
          __typename
        }
        project {
          id
          name
          featured
          active
          representative
          shortDescription
          longDescription
          ContributionText
          urlBanner
          urlMainImage
          urlContributionImage
          urlThumbNail
          callToAction
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProjectRewards = /* GraphQL */ `
  query GetProjectRewards($id: ID!) {
    getProjectRewards(id: $id) {
      id
      rewardsId
      projectId
      rewards {
        id
        Label
        communitys {
          nextToken
          __typename
        }
        projects {
          nextToken
          __typename
        }
        partners {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      project {
        id
        name
        featured
        active
        representative
        shortDescription
        longDescription
        ContributionText
        urlBanner
        urlMainImage
        urlContributionImage
        urlThumbNail
        callToAction
        communitys {
          nextToken
          __typename
        }
        Rewards {
          nextToken
          __typename
        }
        partners {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listProjectRewards = /* GraphQL */ `
  query ListProjectRewards(
    $filter: ModelProjectRewardsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjectRewards(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        rewardsId
        projectId
        rewards {
          id
          Label
          createdAt
          updatedAt
          __typename
        }
        project {
          id
          name
          featured
          active
          representative
          shortDescription
          longDescription
          ContributionText
          urlBanner
          urlMainImage
          urlContributionImage
          urlThumbNail
          callToAction
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const projectRewardsByRewardsId = /* GraphQL */ `
  query ProjectRewardsByRewardsId(
    $rewardsId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProjectRewardsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    projectRewardsByRewardsId(
      rewardsId: $rewardsId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        rewardsId
        projectId
        rewards {
          id
          Label
          createdAt
          updatedAt
          __typename
        }
        project {
          id
          name
          featured
          active
          representative
          shortDescription
          longDescription
          ContributionText
          urlBanner
          urlMainImage
          urlContributionImage
          urlThumbNail
          callToAction
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const projectRewardsByProjectId = /* GraphQL */ `
  query ProjectRewardsByProjectId(
    $projectId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProjectRewardsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    projectRewardsByProjectId(
      projectId: $projectId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        rewardsId
        projectId
        rewards {
          id
          Label
          createdAt
          updatedAt
          __typename
        }
        project {
          id
          name
          featured
          active
          representative
          shortDescription
          longDescription
          ContributionText
          urlBanner
          urlMainImage
          urlContributionImage
          urlThumbNail
          callToAction
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPartnerRewards = /* GraphQL */ `
  query GetPartnerRewards($id: ID!) {
    getPartnerRewards(id: $id) {
      id
      rewardsId
      partnerId
      rewards {
        id
        Label
        communitys {
          nextToken
          __typename
        }
        projects {
          nextToken
          __typename
        }
        partners {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      partner {
        id
        name
        description
        active
        featured
        img_url
        representative
        Projects {
          nextToken
          __typename
        }
        Rewards {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPartnerRewards = /* GraphQL */ `
  query ListPartnerRewards(
    $filter: ModelPartnerRewardsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPartnerRewards(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        rewardsId
        partnerId
        rewards {
          id
          Label
          createdAt
          updatedAt
          __typename
        }
        partner {
          id
          name
          description
          active
          featured
          img_url
          representative
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const partnerRewardsByRewardsId = /* GraphQL */ `
  query PartnerRewardsByRewardsId(
    $rewardsId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPartnerRewardsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    partnerRewardsByRewardsId(
      rewardsId: $rewardsId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        rewardsId
        partnerId
        rewards {
          id
          Label
          createdAt
          updatedAt
          __typename
        }
        partner {
          id
          name
          description
          active
          featured
          img_url
          representative
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const partnerRewardsByPartnerId = /* GraphQL */ `
  query PartnerRewardsByPartnerId(
    $partnerId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPartnerRewardsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    partnerRewardsByPartnerId(
      partnerId: $partnerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        rewardsId
        partnerId
        rewards {
          id
          Label
          createdAt
          updatedAt
          __typename
        }
        partner {
          id
          name
          description
          active
          featured
          img_url
          representative
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPartnerProject = /* GraphQL */ `
  query GetPartnerProject($id: ID!) {
    getPartnerProject(id: $id) {
      id
      partnerId
      projectId
      partner {
        id
        name
        description
        active
        featured
        img_url
        representative
        Projects {
          nextToken
          __typename
        }
        Rewards {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      project {
        id
        name
        featured
        active
        representative
        shortDescription
        longDescription
        ContributionText
        urlBanner
        urlMainImage
        urlContributionImage
        urlThumbNail
        callToAction
        communitys {
          nextToken
          __typename
        }
        Rewards {
          nextToken
          __typename
        }
        partners {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPartnerProjects = /* GraphQL */ `
  query ListPartnerProjects(
    $filter: ModelPartnerProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPartnerProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        partnerId
        projectId
        partner {
          id
          name
          description
          active
          featured
          img_url
          representative
          createdAt
          updatedAt
          __typename
        }
        project {
          id
          name
          featured
          active
          representative
          shortDescription
          longDescription
          ContributionText
          urlBanner
          urlMainImage
          urlContributionImage
          urlThumbNail
          callToAction
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const partnerProjectsByPartnerId = /* GraphQL */ `
  query PartnerProjectsByPartnerId(
    $partnerId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPartnerProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    partnerProjectsByPartnerId(
      partnerId: $partnerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        partnerId
        projectId
        partner {
          id
          name
          description
          active
          featured
          img_url
          representative
          createdAt
          updatedAt
          __typename
        }
        project {
          id
          name
          featured
          active
          representative
          shortDescription
          longDescription
          ContributionText
          urlBanner
          urlMainImage
          urlContributionImage
          urlThumbNail
          callToAction
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const partnerProjectsByProjectId = /* GraphQL */ `
  query PartnerProjectsByProjectId(
    $projectId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPartnerProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    partnerProjectsByProjectId(
      projectId: $projectId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        partnerId
        projectId
        partner {
          id
          name
          description
          active
          featured
          img_url
          representative
          createdAt
          updatedAt
          __typename
        }
        project {
          id
          name
          featured
          active
          representative
          shortDescription
          longDescription
          ContributionText
          urlBanner
          urlMainImage
          urlContributionImage
          urlThumbNail
          callToAction
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
