import React, { useEffect, useState } from "react";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useContext } from "react";
import { Context } from "../Context";
import { Avatar } from "@material-tailwind/react";
import { HashLink } from "react-router-hash-link";
import { partnerNames } from "../MOCKDATA/products";
import { useLocation } from "react-router-dom";
import NavBarHeader from "../ui-components/NavBarHeader";
export default function Navbar() {
  const location = useLocation();
  //console.log(location);
  const { loginWithRedirect, isAuthenticated, logout, user } = useAuth0();
  const { profile, setProfile, context, setPartner, partner, profileDis } =
    useContext(Context);
  const [openMenu, setOpenMenu] = useState(false);
  const navigate = useNavigate();
  const handleLogoOnClick = () => navigate('/');

  useEffect(() => {
    if (isAuthenticated) {
      setProfile(user);

      if (profile.email) {
        // // changing email comment out later
        // const updateEmail = { ...user };
        // updateEmail.email = "stephanie@disney.com";
        // console.log(updateEmail.email);
        // console.log(updateEmail);
        // setProfile(updateEmail);
        // // set the email to a disney email for testing

        setPartner(profile.email.split("@")[1].split(".com")[0]);
        // console.log(profile.email.split("@")[1].split(".com")[0]);
        //console.log(partner);
        // this removes @ from end of email and also removes .com
      }
    } else {
      setProfile("");
      setPartner("");
    }

    //console.log(isAuthenticated);
    //console.log(partner);
  }, [isAuthenticated]);

  useEffect(() => {
    
    if (isAuthenticated) {
      // const updatedTempEmail = { ...user };
      // updatedTempEmail.email = "tom@disney.com";
      // setProfile(updatedTempEmail);
      // uncomment above to test simulate signing in with a disney email
      setProfile(user);
      // <===========================================          ==================================================>

      // <===========================================          ==================================================>
      const emailType = profile?.email?.split("@")[1].split(".com")[0];
      // console.log(partnerNames.filter((name) => name === emailType));
      // console.log(profile.email);
      setPartner(emailType);
      // console.log(partner);
      // this removes @ from end of email and also removes .com
    } else {
      setProfile("");
      setPartner("");
    }

    // console.log(isAuthenticated);
    // console.log(partner);
  }, [isAuthenticated, profile]);

//console.log(partner);

  const authEl = () => {
    if (isAuthenticated) {
      return (
        <div className="flex gap-3 items-center">
          <Avatar
            src={profile.picture}
            alt={profile.given_name}
            className="cursor-pointer"
          />
          <li className="cursor-pointer" onClick={() => logout()}>
            logout
          </li>
        </div>
      );
    } else {
      return (
        <li className="cursor-pointer" onClick={() => loginWithRedirect()}>
          sign in
        </li>
      );
    }
  };

  return (

    <NavBarHeader marginBottom='20px' width='100%'
      overrides={{
        "LogoWithText": { onClick: () => navigate('/') },
        "Page 4": {
          children:
            <HashLink smooth to="/#about/">
              about
            </HashLink>
        },
        "Page 1": { children: <Link to={"/marketplace/"}>marketplace</Link> },
        // need to figure out how to put in conditions here so that if the partner is set, the prefix can be added.
        "Page 2": { children: <Link to="partners">partners</Link> },
        "Page 3": { children: <Link to="/">Communities</Link> }
      }}
    />
  );
}
