/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Flex, Image, Text, View } from "@aws-amplify/ui-react";
export default function MainBanner(props) {
  const { overrides, ...rest } = props;
  return (
    <View
      width="2028px"
      height="684px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
      padding="0px 0px 0px 0px"
      {...getOverrideProps(overrides, "MainBanner")}
      {...rest}
    >
      <Image
        width="calc(100% - 0px)"
        height="calc(100% - 0px)"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="0px"
        bottom="0px"
        left="0px"
        right="0px"
        padding="0px 0px 0px 0px"
        objectFit="cover"
        src="https://main-customer-storage161444-dev.s3.amazonaws.com/public/Images/Background Logo.jpg"
        {...getOverrideProps(overrides, "Background logo 1")}
      ></Image>
      <Flex
        gap="10px"
        direction="column"
        width="1788px"
        height="149px"
        justifyContent="center"
        alignItems="center"
        position="absolute"
        top="calc(50% - 74.5px - -0.5px)"
        left="120px"
        right="120px"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Banner")}
      >
        <Flex
          gap="24px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          backgroundColor="rgba(235,250,237,1)"
          {...getOverrideProps(overrides, "HeroMessage")}
        >
          <Flex
            gap="16px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="20px 20px 20px 20px"
            {...getOverrideProps(overrides, "Message")}
          >
            <Flex
              gap="0"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Frame 438")}
            >
              <Text
                fontFamily="Inter"
                fontSize="48px"
                fontWeight="300"
                color="rgba(13,26,38,1)"
                lineHeight="60px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Green"
                {...getOverrideProps(overrides, "Heading3891528")}
              ></Text>
              <Text
                fontFamily="Inter"
                fontSize="48px"
                fontWeight="300"
                color="rgba(67,168,84,1)"
                lineHeight="60px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="62.04px"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="RR"
                {...getOverrideProps(overrides, "Heading3891529")}
              ></Text>
              <Text
                fontFamily="Inter"
                fontSize="48px"
                fontWeight="300"
                color="rgba(13,26,38,1)"
                lineHeight="60px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="156.63px"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children=" Space"
                {...getOverrideProps(overrides, "Heading3891530")}
              ></Text>
            </Flex>
            <Text
              fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(48,64,80,1)"
              lineHeight="24px"
              textAlign="center"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="0.01px"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Welcome to our website ! We are a dedicated team of individuals who are deeply concerned about the health of our environment and committed to making a positive impact"
              {...getOverrideProps(overrides, "Body")}
            ></Text>
          </Flex>
        </Flex>
      </Flex>
    </View>
  );
}
