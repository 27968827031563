import React, { useEffect, useContext } from 'react';
import { generateClient } from '@aws-amplify/api';
import { getProject } from '../graphql/queries';
import { Context } from '../Context';
const Get_Demo_Project = () => {
  return {
      "urlThumbNail": "https://main-customer-storage161444-dev.s3.amazonaws.com/public/ProjectUploads/disney.jpg",
      "urlMainImage": "https://main-customer-storage161444-dev.s3.amazonaws.com/public/ProjectUploads/disney.jpeg",
      "urlContributionImage": "https://main-customer-storage161444-dev.s3.amazonaws.com/public/ProjectUploads/childrenplanting.jpg",
      "urlBanner": "https://main-customer-storage161444-dev.s3.amazonaws.com/public/ProjectUploads/disney.jpeg",
      "shortDescription": "The Walt Disney Company, commonly known as Disney, is one of the world's most iconic and influential entertainment conglomerates. ",
      "longDescription": "The Walt Disney Company, commonly known as Disney, is one of the world's most iconic and influential entertainment conglomerates. Established in 1923 by Walt Disney and Roy O. Disney, this company has not only created timeless characters and stories but has also built a global brand that spans theme parks, movies, television, merchandise, and more. Disney's influence on popular culture and the entertainment industry is immeasurable, and its ability to captivate and inspire audiences of all ages remains unparalleled. Disney's story began in the 1920s when Walt Disney, a visionary animator, and his brother Roy co-founded the Disney Brothers Studio. The studio later became the Walt Disney Studio. Their early successes included the creation of Mickey Mouse in 1928, marking the birth of an enduring cultural icon. The release of 'Snow White and the Seven Dwarfs' in 1937 was a groundbreaking achievement, as it was the world's first full-length animated feature film. This was a pivotal moment for the company, and it solidified Disney's reputation as a pioneer in animation. Disney's dream extended beyond just animated films. In 1955, Disneyland opened its doors in Anaheim, California, becoming the world's first-ever theme park. This marked the beginning of a new era in family entertainment. The park's immense success led to the creation of additional parks worldwide, including Walt Disney World in Florida, Tokyo Disneyland, Disneyland Paris, Hong Kong Disneyland, and Shanghai Disneyland. These theme parks have become destinations that transport visitors to a world of magic, where imagination knows no bounds.",
      "featured": true,
      "id": "0ec3eb0b-6dff-4967-b8a1-c14e49930c61",
      "callToAction": "Contribute today and support the mouse!",
      "active": true,
      "ContributionText": "Your contribution supports Disney doing the good for us all.",
      "partners": {
          "items": [
              {
                  "partner": {
                      "Rewards": {
                          "items": []
                      },
                      "name": "GreenRR",
                      "id": "f9258d48-963d-413f-a806-ff7860c143c1"
                  }
              },
              {
                  "partner": {
                      "Rewards": {
                          "items": []
                      },
                      "name": "Disney",
                      "id": "c28acf16-93c5-4c47-8ab2-e89f0a5c1cd4"
                  }
              }
          ]
      },
      "communitys": {
          "items": [
              {
                  "id": "f831e3c9-a9a1-4969-be73-307173599ee7",
                  "communityId": "e5616eb5-c800-417c-9acc-cfcbf8670cd4",
                  "community": {
                      "name": "LearneRR",
                      "id": "e5616eb5-c800-417c-9acc-cfcbf8670cd4",
                      "Rewards": {
                          "items": [
                              {
                                  "rewards": {
                                      "Label": "5% LearneRR Token Reward",
                                      "id": "adf4a640-2184-49b5-b78d-51f2789a6db7"
                                  }
                              },
                              {
                                  "rewards": {
                                      "Label": "Pay it forward",
                                      "id": "0cbef2ac-8997-455a-ba9a-9199a0a4b432"
                                  }
                              }
                          ]
                      }
                  }
              }
          ]
      },
      "Rewards": {
          "items": []
      }
  }
}
  ;

const useProjectDetails = (projID) => {
  const { project, setProject } = useContext(Context);

  useEffect(() => {
    const fetchProject = async () => {
      try {
        ;
        if (project?.id === projID) {
          console.log('Returning original');
          return project;
        }
        console.log(project?.id);
        console.log('Looking up project with ID: ' + projID);
        const projectId = projID;
        const api = generateClient();

        const response = await api.graphql({
          query: getProject,
          variables: { id: projectId },
        });

        if (response.errors) {
          console.error("GraphQL Errors:", response.errors);
        } else {
          const projectData = response.data.getProject;
          setProject(projectData);
          return projectData;
        }
      } catch (error) {
        console.error('Error fetching project:', error);
        throw error;
      }
    };

    fetchProject();
  }, [projID, project?.id, setProject]);

  return project;
};

export default useProjectDetails
