import React, { useEffect, useState } from 'react';
import { generateClient, graphqlOperation } from '@aws-amplify/api';
import { listProjects } from '../graphql/queries'; // Adjust the path

const FeaturedProjects = () => {
    const [featuredProjects, setFeaturedProjects] = useState([]);

    useEffect(() => {
        const fetchFeaturedProjects = async () => {
            try {
                const api = generateClient();

                // Manually construct the GraphQL operation
                const response = await api.graphql({
                    query: listProjects, // Your GraphQL query
                    variables: {
                        filter: {
                            featured: {
                                eq: true,
                            },
                        },
                        limit: 10, // Adjust the limit as needed
                    },
                });

                const projectsData = response.data.listProjects.items;

                setFeaturedProjects(projectsData);
            } catch (error) {
                console.error('Error fetching featured projects:', error);
            }
        };

        fetchFeaturedProjects();
    }, []); // Run once on component mount

    return featuredProjects;
};

const NewProjects = () => {
    const [newProjects, setNewProjects] = useState([]);

    useEffect(() => {
        const fetchNewProjects = async () => {
            try {
                const api = generateClient();

                // Manually construct the GraphQL operation for new projects
                const response = await api.graphql({
                    query: listProjects, // Your GraphQL query
                    variables: {
                        filter: {},
                        limit: 10, // Adjust the limit as needed
                        // Add sorting by createdAt to get the newest projects
                        sortDirection: 'DESC',
                        sortBy: 'createdAt',
                    },
                });

                const projectsData = response.data.listProjects.items;

                setNewProjects(projectsData);
            } catch (error) {
                console.error('Error fetching new projects:', error);
            }
        };

        fetchNewProjects();
    }, []); // Run once on component mount

    return newProjects;
};

const ProjectsByPartner = ({ PartnerID }) => { // Accept PartnerID as a parameter
    const [partnerProjects, setPartnerProjects] = useState([]);
    const location = useLocation();

    useEffect(() => {
        const fetchPartnerProjects = async () => {
            try {
                const api = generateClient();

                // Manually construct the GraphQL operation with PartnerID filter
                const response = await api.graphql({
                    query: listProjects,
                    variables: {
                        filter: {
                            partnerID: {
                                eq: PartnerID,
                            },
                        },
                        limit: 10, // Adjust the limit as needed
                    },
                });

                const projectsData = response.data.listProjects.items;

                setPartnerProjects(projectsData);
            } catch (error) {
                console.error('Error fetching partner projects:', error);
            }
        };

        if (PartnerID) {
            fetchPartnerProjects();
        }
    }, [PartnerID]); // Run whenever PartnerID changes

    return partnerProjects;
};

const Get_Demo_Projects = () => {
    return
    [
        {
            "urlThumbNail": "https://main-customer-storage161444-dev.s3.amazonaws.com/public/ProjectUploads/disney.jpg",
            "shortDescription": "The Walt Disney Company, commonly known as Disney, is one of the world's most iconic and influential entertainment conglomerates. ",
            "name": "Project Disney",
            "id": "0ec3eb0b-6dff-4967-b8a1-c14e49930c61",
            "active": true,
            "featured": true,
            "Rewards": {
                "items": []
            },
            "communitys": {
                "items": [
                    {
                        "community": {
                            "Rewards": {
                                "items": [
                                    {
                                        "rewards": {
                                            "id": "adf4a640-2184-49b5-b78d-51f2789a6db7",
                                            "Label": "5% LearneRR Token Reward"
                                        }
                                    },
                                    {
                                        "rewards": {
                                            "id": "0cbef2ac-8997-455a-ba9a-9199a0a4b432",
                                            "Label": "Pay it forward"
                                        }
                                    }
                                ]
                            },
                            "id": "e5616eb5-c800-417c-9acc-cfcbf8670cd4",
                            "name": "LearneRR"
                        }
                    }
                ]
            },
            "partners": {
                "items": [
                    {
                        "id": "a3a982cc-b4ef-466f-9a15-77195f07bbed",
                        "partner": {
                            "Rewards": {
                                "items": [
                                    {
                                        "Label": "5% WateRR Token Reward",
                                        "id": "07851c61-94ad-4b2a-8dc9-bc0b9b47dc6f"
                                    },
                                    {
                                        "Label": "5% LearneRR Token Reward",
                                        "id": "adf4a640-2184-49b5-b78d-51f2789a6db7"
                                    },
                                    {
                                        "Label": "5% TreeRR Token Reward",
                                        "id": "484a859d-a53c-4665-a371-87eec343d281"
                                    },
                                    {
                                        "Label": "Pay it forward",
                                        "id": "0cbef2ac-8997-455a-ba9a-9199a0a4b432"
                                    }
                                ]
                            }
                        }
                    },
                    {
                        "id": "ba3572a8-2802-4bc6-94d5-b469fdd40422",
                        "partner": {
                            "Rewards": {
                                "items": [
                                    {
                                        "Label": "3 Months Free Disney+",
                                        "id": "ff8822d4-1c62-4c23-8fd5-25d25aaf436a"
                                    }
                                ]
                            }
                        }
                    }
                ]
            }
        },
        {
            "urlThumbNail": "https://main-customer-storage161444-dev.s3.amazonaws.com/public/ProjectUploads/img3.jpg",
            "shortDescription": "Antarctica, often considered one of the least populated lands on our planet, stands as an awe-inspiring testament to the raw beauty of untouched wilderness. This remote continent, covered in ice and surrounded by frigid waters, has remained largely free from the influence of human habitation for countless years.",
            "name": "Fresh Air",
            "id": "d47411d7-a34d-4cc2-baba-d9ca16b816b6",
            "active": true,
            "featured": true,
            "Rewards": {
                "items": []
            },
            "communitys": {
                "items": [
                    {
                        "community": {
                            "Rewards": {
                                "items": [
                                    {
                                        "rewards": {
                                            "id": "adf4a640-2184-49b5-b78d-51f2789a6db7",
                                            "Label": "5% LearneRR Token Reward"
                                        }
                                    },
                                    {
                                        "rewards": {
                                            "id": "0cbef2ac-8997-455a-ba9a-9199a0a4b432",
                                            "Label": "Pay it forward"
                                        }
                                    }
                                ]
                            },
                            "id": "e5616eb5-c800-417c-9acc-cfcbf8670cd4",
                            "name": "LearneRR"
                        }
                    }
                ]
            },
            "partners": {
                "items": [
                    {
                        "id": "6fec07ae-57a1-4c14-8e50-42c5365bee0c",
                        "partner": {
                            "Rewards": {
                                "items": [
                                    {
                                        "Label": "5% WateRR Token Reward",
                                        "id": "07851c61-94ad-4b2a-8dc9-bc0b9b47dc6f"
                                    },
                                    {
                                        "Label": "5% LearneRR Token Reward",
                                        "id": "adf4a640-2184-49b5-b78d-51f2789a6db7"
                                    },
                                    {
                                        "Label": "5% TreeRR Token Reward",
                                        "id": "484a859d-a53c-4665-a371-87eec343d281"
                                    },
                                    {
                                        "Label": "Pay it forward",
                                        "id": "0cbef2ac-8997-455a-ba9a-9199a0a4b432"
                                    }
                                ]
                            }
                        }
                    }
                ]
            }
        },
        {
            "urlThumbNail": "https://main-customer-storage161444-dev.s3.amazonaws.com/public/ProjectUploads/childrenplanting.webp",
            "shortDescription": "Every tree counts and for just one dollar you can contribute to the total.",
            "name": "One Tree Planted",
            "id": "62de67ae-8886-4701-a737-a16c37e36be2",
            "active": true,
            "featured": true,
            "Rewards": {
                "items": []
            },
            "communitys": {
                "items": [
                    {
                        "community": {
                            "Rewards": {
                                "items": [
                                    {
                                        "rewards": {
                                            "id": "484a859d-a53c-4665-a371-87eec343d281",
                                            "Label": "5% TreeRR Token Reward"
                                        }
                                    },
                                    {
                                        "rewards": {
                                            "id": "0cbef2ac-8997-455a-ba9a-9199a0a4b432",
                                            "Label": "Pay it forward"
                                        }
                                    }
                                ]
                            },
                            "id": "eedd0127-89e4-4379-8d2e-7a5817379ace",
                            "name": "TreeRR"
                        }
                    }
                ]
            },
            "partners": {
                "items": [
                    {
                        "id": "2c7805c6-6d18-4294-b991-d8db1bcd2138",
                        "partner": {
                            "Rewards": {
                                "items": [
                                    {
                                        "Label": "5% WateRR Token Reward",
                                        "id": "07851c61-94ad-4b2a-8dc9-bc0b9b47dc6f"
                                    },
                                    {
                                        "Label": "5% LearneRR Token Reward",
                                        "id": "adf4a640-2184-49b5-b78d-51f2789a6db7"
                                    },
                                    {
                                        "Label": "5% TreeRR Token Reward",
                                        "id": "484a859d-a53c-4665-a371-87eec343d281"
                                    },
                                    {
                                        "Label": "Pay it forward",
                                        "id": "0cbef2ac-8997-455a-ba9a-9199a0a4b432"
                                    }
                                ]
                            }
                        }
                    }
                ]
            }
        },
        {
            "urlThumbNail": "https://main-customer-storage161444-dev.s3.amazonaws.com/public/ProjectUploads/img3.jpg",
            "shortDescription": "Antarctica, often considered one of the least populated lands on our planet, stands as an awe-inspiring testament to the raw beauty of untouched wilderness. This remote continent, covered in ice and surrounded by frigid waters, has remained largely free from the influence of human habitation for countless years.",
            "name": "The Antartic Project",
            "id": "b029799f-f361-4f74-a257-f02c40816ef9",
            "active": true,
            "featured": true,
            "Rewards": {
                "items": []
            },
            "communitys": {
                "items": [
                    {
                        "community": {
                            "Rewards": {
                                "items": [
                                    {
                                        "rewards": {
                                            "id": "adf4a640-2184-49b5-b78d-51f2789a6db7",
                                            "Label": "5% LearneRR Token Reward"
                                        }
                                    },
                                    {
                                        "rewards": {
                                            "id": "0cbef2ac-8997-455a-ba9a-9199a0a4b432",
                                            "Label": "Pay it forward"
                                        }
                                    }
                                ]
                            },
                            "id": "e5616eb5-c800-417c-9acc-cfcbf8670cd4",
                            "name": "LearneRR"
                        }
                    }
                ]
            },
            "partners": {
                "items": [
                    {
                        "id": "5a62ad42-1f0b-4b75-beca-ec05798feca6",
                        "partner": {
                            "Rewards": {
                                "items": [
                                    {
                                        "Label": "5% WateRR Token Reward",
                                        "id": "07851c61-94ad-4b2a-8dc9-bc0b9b47dc6f"
                                    },
                                    {
                                        "Label": "5% LearneRR Token Reward",
                                        "id": "adf4a640-2184-49b5-b78d-51f2789a6db7"
                                    },
                                    {
                                        "Label": "5% TreeRR Token Reward",
                                        "id": "484a859d-a53c-4665-a371-87eec343d281"
                                    },
                                    {
                                        "Label": "Pay it forward",
                                        "id": "0cbef2ac-8997-455a-ba9a-9199a0a4b432"
                                    }
                                ]
                            }
                        }
                    }
                ]
            }
        }
    ]
}

    ;

const Get_Demo_Featured_Projects = () => {
    return [
        {
            "urlThumbNail": "https://main-customer-storage161444-dev.s3.amazonaws.com/public/ProjectUploads/disney.jpg",
            "shortDescription": "The Walt Disney Company, commonly known as Disney, is one of the world's most iconic and influential entertainment conglomerates. ",
            "name": "Project Disney",
            "id": "0ec3eb0b-6dff-4967-b8a1-c14e49930c61",
            "active": true,
            "featured": true,
            "Rewards": {
                "items": []
            },
            "communitys": {
                "items": [
                    {
                        "community": {
                            "Rewards": {
                                "items": [
                                    {
                                        "rewards": {
                                            "id": "adf4a640-2184-49b5-b78d-51f2789a6db7",
                                            "Label": "5% LearneRR Token Reward"
                                        }
                                    },
                                    {
                                        "rewards": {
                                            "id": "0cbef2ac-8997-455a-ba9a-9199a0a4b432",
                                            "Label": "Pay it forward"
                                        }
                                    }
                                ]
                            },
                            "id": "e5616eb5-c800-417c-9acc-cfcbf8670cd4",
                            "name": "LearneRR"
                        }
                    }
                ]
            },
            "partners": {
                "items": [
                    {
                        "id": "a3a982cc-b4ef-466f-9a15-77195f07bbed",
                        "partner": {
                            "Rewards": {
                                "items": [
                                    {
                                        "Label": "5% WateRR Token Reward",
                                        "id": "07851c61-94ad-4b2a-8dc9-bc0b9b47dc6f"
                                    },
                                    {
                                        "Label": "5% LearneRR Token Reward",
                                        "id": "adf4a640-2184-49b5-b78d-51f2789a6db7"
                                    },
                                    {
                                        "Label": "5% TreeRR Token Reward",
                                        "id": "484a859d-a53c-4665-a371-87eec343d281"
                                    },
                                    {
                                        "Label": "Pay it forward",
                                        "id": "0cbef2ac-8997-455a-ba9a-9199a0a4b432"
                                    }
                                ]
                            }
                        }
                    },
                    {
                        "id": "ba3572a8-2802-4bc6-94d5-b469fdd40422",
                        "partner": {
                            "Rewards": {
                                "items": [
                                    {
                                        "Label": "3 Months Free Disney+",
                                        "id": "ff8822d4-1c62-4c23-8fd5-25d25aaf436a"
                                    }
                                ]
                            }
                        }
                    }
                ]
            }
        },
        {
            "urlThumbNail": "https://main-customer-storage161444-dev.s3.amazonaws.com/public/ProjectUploads/img3.jpg",
            "shortDescription": "Antarctica, often considered one of the least populated lands on our planet, stands as an awe-inspiring testament to the raw beauty of untouched wilderness. This remote continent, covered in ice and surrounded by frigid waters, has remained largely free from the influence of human habitation for countless years.",
            "name": "Fresh Air",
            "id": "d47411d7-a34d-4cc2-baba-d9ca16b816b6",
            "active": true,
            "featured": true,
            "Rewards": {
                "items": []
            },
            "communitys": {
                "items": [
                    {
                        "community": {
                            "Rewards": {
                                "items": [
                                    {
                                        "rewards": {
                                            "id": "adf4a640-2184-49b5-b78d-51f2789a6db7",
                                            "Label": "5% LearneRR Token Reward"
                                        }
                                    },
                                    {
                                        "rewards": {
                                            "id": "0cbef2ac-8997-455a-ba9a-9199a0a4b432",
                                            "Label": "Pay it forward"
                                        }
                                    }
                                ]
                            },
                            "id": "e5616eb5-c800-417c-9acc-cfcbf8670cd4",
                            "name": "LearneRR"
                        }
                    }
                ]
            },
            "partners": {
                "items": [
                    {
                        "id": "6fec07ae-57a1-4c14-8e50-42c5365bee0c",
                        "partner": {
                            "Rewards": {
                                "items": [
                                    {
                                        "Label": "5% WateRR Token Reward",
                                        "id": "07851c61-94ad-4b2a-8dc9-bc0b9b47dc6f"
                                    },
                                    {
                                        "Label": "5% LearneRR Token Reward",
                                        "id": "adf4a640-2184-49b5-b78d-51f2789a6db7"
                                    },
                                    {
                                        "Label": "5% TreeRR Token Reward",
                                        "id": "484a859d-a53c-4665-a371-87eec343d281"
                                    },
                                    {
                                        "Label": "Pay it forward",
                                        "id": "0cbef2ac-8997-455a-ba9a-9199a0a4b432"
                                    }
                                ]
                            }
                        }
                    }
                ]
            }
        },
        {
            "urlThumbNail": "https://main-customer-storage161444-dev.s3.amazonaws.com/public/ProjectUploads/childrenplanting.webp",
            "shortDescription": "Every tree counts and for just one dollar you can contribute to the total.",
            "name": "One Tree Planted",
            "id": "62de67ae-8886-4701-a737-a16c37e36be2",
            "active": true,
            "featured": true,
            "Rewards": {
                "items": []
            },
            "communitys": {
                "items": [
                    {
                        "community": {
                            "Rewards": {
                                "items": [
                                    {
                                        "rewards": {
                                            "id": "484a859d-a53c-4665-a371-87eec343d281",
                                            "Label": "5% TreeRR Token Reward"
                                        }
                                    },
                                    {
                                        "rewards": {
                                            "id": "0cbef2ac-8997-455a-ba9a-9199a0a4b432",
                                            "Label": "Pay it forward"
                                        }
                                    }
                                ]
                            },
                            "id": "eedd0127-89e4-4379-8d2e-7a5817379ace",
                            "name": "TreeRR"
                        }
                    }
                ]
            },
            "partners": {
                "items": [
                    {
                        "id": "2c7805c6-6d18-4294-b991-d8db1bcd2138",
                        "partner": {
                            "Rewards": {
                                "items": [
                                    {
                                        "Label": "5% WateRR Token Reward",
                                        "id": "07851c61-94ad-4b2a-8dc9-bc0b9b47dc6f"
                                    },
                                    {
                                        "Label": "5% LearneRR Token Reward",
                                        "id": "adf4a640-2184-49b5-b78d-51f2789a6db7"
                                    },
                                    {
                                        "Label": "5% TreeRR Token Reward",
                                        "id": "484a859d-a53c-4665-a371-87eec343d281"
                                    },
                                    {
                                        "Label": "Pay it forward",
                                        "id": "0cbef2ac-8997-455a-ba9a-9199a0a4b432"
                                    }
                                ]
                            }
                        }
                    }
                ]
            }
        },
        {
            "urlThumbNail": "https://main-customer-storage161444-dev.s3.amazonaws.com/public/ProjectUploads/img3.jpg",
            "shortDescription": "Antarctica, often considered one of the least populated lands on our planet, stands as an awe-inspiring testament to the raw beauty of untouched wilderness. This remote continent, covered in ice and surrounded by frigid waters, has remained largely free from the influence of human habitation for countless years.",
            "name": "The Antartic Project",
            "id": "b029799f-f361-4f74-a257-f02c40816ef9",
            "active": true,
            "featured": true,
            "Rewards": {
                "items": []
            },
            "communitys": {
                "items": [
                    {
                        "community": {
                            "Rewards": {
                                "items": [
                                    {
                                        "rewards": {
                                            "id": "adf4a640-2184-49b5-b78d-51f2789a6db7",
                                            "Label": "5% LearneRR Token Reward"
                                        }
                                    },
                                    {
                                        "rewards": {
                                            "id": "0cbef2ac-8997-455a-ba9a-9199a0a4b432",
                                            "Label": "Pay it forward"
                                        }
                                    }
                                ]
                            },
                            "id": "e5616eb5-c800-417c-9acc-cfcbf8670cd4",
                            "name": "LearneRR"
                        }
                    }
                ]
            },
            "partners": {
                "items": [
                    {
                        "id": "5a62ad42-1f0b-4b75-beca-ec05798feca6",
                        "partner": {
                            "Rewards": {
                                "items": [
                                    {
                                        "Label": "5% WateRR Token Reward",
                                        "id": "07851c61-94ad-4b2a-8dc9-bc0b9b47dc6f"
                                    },
                                    {
                                        "Label": "5% LearneRR Token Reward",
                                        "id": "adf4a640-2184-49b5-b78d-51f2789a6db7"
                                    },
                                    {
                                        "Label": "5% TreeRR Token Reward",
                                        "id": "484a859d-a53c-4665-a371-87eec343d281"
                                    },
                                    {
                                        "Label": "Pay it forward",
                                        "id": "0cbef2ac-8997-455a-ba9a-9199a0a4b432"
                                    }
                                ]
                            }
                        }
                    }
                ]
            }
        }
    ]
};


const Get_Demo_Partner_Projects = () => {
    return [
        {
            "partnerId": "c28acf16-93c5-4c47-8ab2-e89f0a5c1cd4",
            "partner": {
                "Rewards": {
                    "items": []
                }
            },
            "project": {
                "urlThumbNail": "https://main-customer-storage161444-dev.s3.amazonaws.com/public/ProjectUploads/disney.jpg",
                "shortDescription": "The Walt Disney Company, commonly known as Disney, is one of the world's most iconic and influential entertainment conglomerates. ",
                "name": "Project Disney",
                "id": "0ec3eb0b-6dff-4967-b8a1-c14e49930c61",
                "featured": true,
                "communitys": {
                    "items": [
                        {
                            "community": {
                                "Rewards": {
                                    "items": [
                                        {
                                            "rewards": {
                                                "Label": "5% LearneRR Token Reward",
                                                "id": "adf4a640-2184-49b5-b78d-51f2789a6db7"
                                            }
                                        },
                                        {
                                            "rewards": {
                                                "Label": "Pay it forward",
                                                "id": "0cbef2ac-8997-455a-ba9a-9199a0a4b432"
                                            }
                                        }
                                    ]
                                },
                                "name": "LearneRR",
                                "id": "e5616eb5-c800-417c-9acc-cfcbf8670cd4"
                            }
                        }
                    ]
                },
                "Rewards": {
                    "items": []
                }
            }
        }
    ]

};



export { FeaturedProjects, NewProjects, ProjectsByPartner, Get_Demo_Projects, Get_Demo_Featured_Projects, Get_Demo_Partner_Projects };
