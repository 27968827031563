import React from "react";
import Header from "../components/Header";
import About from "../components/About";
import Phase from "../components/Phase";
import ContactForm from "../components/ContactForm";
import Navbar from "../components/Navbar";

export default function MainPage() {
  return (
    <>
      {/* <Navbar /> */}
      <Header/>
      <About />
      <div className="flex flex-col gap-10 w-full z-10 overflow-hidden">
        <h3 className="lg-txt-x sec-div">The Big Plan</h3>
        <Phase />
      </div>
      <ContactForm />
    </>
  );
}
