import React, { useState } from "react";
import Imageslider from "./Imageslider";
import fence from '../assets/fence.jpg';
import plant from '../assets/plant.jpg';
import children from "../assets/childrenplanting.jpg";
import { CarouselWithContent } from "./ui/CarouselWithContent";
// just some comments
export default function Phase() {
  const [slides, setSlides] = useState([
    {
      image: fence, 
      title: "Phase 1",
      text: "Our first program will be Plant a Tree - Get a Coin! Companies that care about ESG can multiply their impact 3-4x and engage their stakeholders by sponsoring this program.Participating individuals will receive a cryptocurrency reward and optionally a governance token. Our market research indicates these rewards will double participation.",
      alt: "image 1",
    },
    {
      image: plant,
      title: "Phase 2",
      text: 'We will expand our decentralized finance "DeFi" application beyond projects to support research and education around environmental sustainability. Stakeholders at all levels can participate in governance, and both funders and project actors will have the option of being rewarded with cryptocurrency.',
      alt: "image 2",
    },
    {
      image: children,
      title: "Phase 3",
      text: "Governance will be spun off into multiple Decentralized Autonomous Organizations, with each group responsible for different types of environmental projects.",
      alt: "image 3",
    },
  ]);
  return (
    <div className="flex flex-col gap-10 h-[400px]">
      <div className="h-full">
        <CarouselWithContent slides={slides} />
      </div>
    </div>
  );
}
