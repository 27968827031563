import React from 'react';

export const Context = React.createContext();

// export const ContextProvider = ({ children }) => {
//   const [project, setProject] = React.useState(null);

//   const contextValues = {
//     itemId,
//     setItemId,
//     profile,
//     setProfile,
//     context,
//     profileDis,
//     partner,
//     setPartner,
//     project,
//     setProject,
//   };

//   return <Context.Provider value={contextValues}>{children}</Context.Provider>;
// };
