import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import useProjectDetails from "../Query Components/LookUpProj.jsx";
import ProjectPage from "../ui-components/ProjectPage.jsx";
import CheckoutModal from "../components/CheckoutModal.jsx";
import { Context } from "../Context.jsx";
import { Radio, RadioGroupField, Button, Flex, TextField } from "@aws-amplify/ui-react";

const Product = () => {
  const { project } = useContext(Context);
  const { id } = useParams();
  useProjectDetails(id);

  const [isDonateClicked, setDonateClicked] = useState(false);
  const [donationAmount, setDonationAmount] = useState(25);
  const [reward, setReward] = useState(null); // Set initial value to null
  const availableRewards = ['3 Months Disney+', '5% GreenRR Token', 'Pay It Forward'];

  useEffect(() => {
    // Set the default reward when the component mounts
    if (availableRewards.length > 0 && !reward) { // Only set the default reward if it's not already set
      setReward(availableRewards[0]);
    }
  }, [availableRewards, reward]);

  const handleDonateClick = () => {
    const amount = document.getElementById("txtDonationValue").value;

    if (amount > 0) {
      setDonationAmount(amount);
      setDonateClicked(true);
    }
  };

  const handleRewardChange = (e) => {
    console.log(e.target.value);
    setReward(e.target.value);
  }

  const generateRadios = (rewards) => {
    return rewards.map((reward, index) => (
      <Radio key={index} value={reward}>
        {reward}
      </Radio>
    ));
  }

  if (!project) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <ProjectPage project={project} />
      <RadioGroupField
        legend="Available Rewards:"
        name="Reward"
        value={reward}
        onChange={handleRewardChange}
      >
        {generateRadios(availableRewards)}
      </RadioGroupField>

      <Flex gap="1rem" direction="column">
        <TextField
          id="txtDonationValue"
          outerEndComponent={<Button onClick={handleDonateClick}>Donate</Button>}
        />
      </Flex>

      {donationAmount > 0 && isDonateClicked && (
        <CheckoutModal
          isOpen={isDonateClicked}
          onClose={() => setDonateClicked(false)}
          project={project}
          donationAmount={donationAmount}
          selectedReward={reward}
        />
      )}
    </>
  );
};

export default Product;
